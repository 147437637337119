var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-overview',{attrs:{"title":"Applied Rules","icon":"far fa-fw fa-globe","store":"validationRule/submissions","headers":[
    { text: 'Completed at', value: 'completed_at' },
    { text: 'Name', value: 'full_name', sortable: false },
    { text: 'Website', value: 'website', sortable: false },
    { text: 'Subjects', value: 'subjects', sortable: false },
    { text: 'Leads', value: 'leads', sortable: false },
    { text: 'Status', value: 'id', sortable: false },
    {
      text: 'Details',
      align: 'center',
      sortable: false,
      class: 'v-table-th--icon',
      rolesOrPermissions: 'submission_view'
    }
  ],"force-initial-sort":"completed_at","descending":""},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
  var item = ref.item;
return _c('validation-rule-applied-table-row',{attrs:{"submission-id":_vm.submission(item)}})}}])})}
var staticRenderFns = []

export { render, staticRenderFns }