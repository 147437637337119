<template>
  <v-chip label>
    <div>
      {{ leadSubjectName }}
      <validation-rule-submission-table-row-lead-offer
        v-for="offerId in lead.offers"
        :key="offerId"
        :offer-id="offerId"
      />
    </div>
  </v-chip>
</template>
<script type="text/babel">
import { isNil } from "lodash";
import ValidationRuleSubmissionTableRowLeadOffer from "@/components/validation-rule/submission/ValidationRuleSubmissionTableRowLeadOffer";

export default {
  name: "validation-rule-submission-table-row-lead",
  components: { ValidationRuleSubmissionTableRowLeadOffer },
  props: {
    leadId: {
      type: Number,
      required: true
    }
  },
  computed: {
    lead() {
      return this.$store.state.validationRule.submissions.lead[this.leadId];
    },
    leadSubject() {
      return this.$store.state.validationRule.submissions.subject[
        this.lead.defaultSubject
      ];
    },
    leadSubjectName() {
      return isNil(this.leadSubject) ? undefined : this.leadSubject.name;
    }
  }
};
</script>
