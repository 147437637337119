<template>
  <div>
    <validation-rule :match-data="triggerGroupMatchData" />
  </div>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import ValidationRule from "@/components/validation-rule/ValidationRule";
const { mapGetters } = createNamespacedHelpers("validationRule");

export default {
  name: "validation-Rule-dashboard-view",
  inject: ["$validator"],
  components: {
    ValidationRule
  },
  computed: {
    ...mapGetters([]),
    triggerGroupMatchData() {
      return this.$store.state.validationRule.ruleTriggerGroups
        .ruleTriggerGroupMatchData;
    }
  },
  data: () => ({})
};
</script>
