<template>
  <v-card color="white">
    <crud-header
      :title="name"
      type="Validation Rule"
      store="validationRule"
      @save-success="
        ({ id }) =>
          $router.push({
            name: 'validation-rules.show',
            params: { validationRuleId: id }
          })
      "
    />
    <validation-rule />
  </v-card>
</template>

<script type="text/babel">
import validationRuleModule from "@/store/modules/validation-rule";
import CrudHeader from "../../components/generic/crud/CrudHeader";
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("validationRule");
import { mapCreateViewLifecycleMethods } from "../helpers";
import ValidationRule from "../../components/validation-rule/ValidationRule";

const createViewLifecycleMethods = mapCreateViewLifecycleMethods(
  "validationRule",
  validationRuleModule
);

export default {
  name: "validation-create-view",
  components: {
    CrudHeader,
    ValidationRule
  },
  data: () => ({}),
  computed: {
    ...mapGetters(["name"])
  },
  ...createViewLifecycleMethods
};
</script>
