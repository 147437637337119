<template>
  <v-chip
    class="submission-state-label"
    :color="color"
    small
    label
    dark
    :selected="selected"
  >
    <i :class="['submission-state-icon', `far ${statusIcon} fa-fw`]" />
    <span class="submission-state-label__state">{{ state }}</span>
  </v-chip>
</template>
<script type="text/babel">
export default {
  name: "submission-state-chip",
  props: {
    state: {
      type: String,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    statusIcon() {
      switch (this.state) {
        case "separated":
        case "processed":
          return "far fa-check-circle";
        case "rejected":
        case "soft-rejected":
          return "far fa-vote-nay";
        default:
          return "far fa-exclamation-circle";
      }
    },
    color() {
      switch (this.state) {
        case "separated":
        case "processed":
          return "success";
        case "rejected":
          return "error";
        default:
          return "warning";
      }
    }
  }
};
</script>
