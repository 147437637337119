<script type="text/babel">
import BAutocomplete from "@/components/generic/BAutocomplete";
import BSelectWrapperMixin from "@/components/generic/BSelectWrapperMixin";

export default {
  name: "b-autocomplete-rejection-reason",
  mixins: [BSelectWrapperMixin],
  components: { BAutocomplete }
};
</script>

<template>
  <b-autocomplete
    v-model="internalValue"
    v-bind="selectProperties"
    store="rejection-reason-select"
    :label="multiple ? 'Rejection reasons' : 'Rejection reason'"
    item-text="name"
    autocomplete="different-languages"
  />
</template>
