<template>
  <tr>
    <td>
      {{ submission.completedAtCorrect | momentFromNow($i18n.locale) }}
    </td>
    <td>{{ submission.fullName }}</td>
    <td>{{ websiteHost }}</td>
    <td>
      <v-chip v-for="subject in subjects" :key="subject.id" label>
        {{ subject.name }}
      </v-chip>
    </td>
    <td>
      <validation-rule-submission-table-row-lead
        v-for="leadId in submission.leads"
        :key="leadId"
        :lead-id="leadId"
      />
    </td>
    <td>
      <submission-state-chip :state="submission.state" />
    </td>
    <td>
      <v-btn
        icon
        :to="{
          name: 'submissions.show',
          params: { submissionId: submission.id }
        }"
      >
        <v-icon>far fa-search</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";
import ValidationRuleSubmissionTableRowLead from "@/components/validation-rule/submission/ValidationRuleSubmissionTableRowLead";
import SubmissionStateChip from "@/components/submission/SubmissionStatusChip";

const { mapGetters } = createNamespacedHelpers("validationRule/submissions");

export default {
  name: "validation-rule-applied-table-row",
  components: { SubmissionStateChip, ValidationRuleSubmissionTableRowLead },
  props: {
    submissionId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters([FIND_BY_ID]),
    submission() {
      return this.$store.state.validationRule.submissions.submission[
        this.submissionId
      ];
    },
    website() {
      return this.$store.state.validationRule.submissions.website[
        this.submission.website
      ];
    },
    websiteHost() {
      return this.website.host;
    },
    subjects() {
      return Object.values(
        this.$store.state.validationRule.submissions.subject
      ).filter(subject => this.submission.subjects.indexOf(subject.id) > -1);
    }
  }
};
</script>
