<template>
  <div class="pa-3">
    <b-text-field v-model="name" label="Name" required />
    <b-select
      v-model="action"
      :items="[
        { value: 'REJECT', text: 'Reject' },
        { value: 'VERIFY', text: 'Flag' }
      ]"
      label="Action"
      required
    />
    <b-autocomplete-rejection-reason v-model="rejectReason" />
    <div class="d-flex">
      <div class="flex-1" />
      <div class="flex-1">
        <b-switch v-model="isActive" label="Active" />
      </div>
      <div class="flex-1">
        <b-switch v-model="isIgnorable" label="Ignorable" />
      </div>
      <div class="flex-1">
        <b-switch v-model="isRejectable" label="Rejectable" />
      </div>
      <div class="flex-1" />
    </div>
    <submission-triggers
      store="validationRule"
      :entity-name="entityName"
      :match-data="matchData"
    />
  </div>
</template>

<script type="text/babel">
import SubmissionTriggers from "../submission-trigger/SubmissionTriggers";
import { createNamespacedHelpers } from "@/store/helpers";
import BAutocompleteRejectionReason from "@/components/reject-reason/BAutocompleteRejectionReason";
import BSelect from "@/components/generic/BSelect";
import BTextField from "@/components/generic/BTextField";
import BSwitch from "@/components/generic/BSwitch";

const { mapComputed } = createNamespacedHelpers("validationRule");

export default {
  name: "validation-rule",
  inject: ["$validator"],
  components: {
    BSwitch,
    BTextField,
    BSelect,
    BAutocompleteRejectionReason,
    SubmissionTriggers
  },
  props: {
    matchData: {
      type: Object,
      default: undefined
    }
  },
  data: () => ({
    entityName: "validationRule",
    submissionIdToCheck: null,
    triggerGroupMatchData: undefined
  }),
  computed: {
    ...mapComputed([
      "name",
      "action",
      "isActive",
      "isIgnorable",
      "isRejectable",
      "rejectReason"
    ])
  }
};
</script>
