<template>
  <tr>
    <td>{{ validationRule.name }}</td>
    <td>
      {{ validationRule.action }}
      <v-chip
        v-if="hasRejectReason"
        label
        small
        :color="validationRule.action === 'VERIFY' ? 'warning' : 'error'"
      >
        {{ rejectReason.name }}
      </v-chip>
    </td>
    <td class="text-sm-right">
      <v-icon v-if="validationRule.isActive" color="green">
        check
      </v-icon>
      <v-icon v-else color="grey">
        clear
      </v-icon>
    </td>
    <td>
      <v-btn
        icon
        :to="{ name: 'validation-rules.show', params: { validationRuleId } }"
      >
        <v-icon>far fa-search</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script type="text/babel">
import { isNil } from "lodash";
import { createNamespacedHelpers } from "vuex";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const { mapGetters: mapValidationRulesGetters } = createNamespacedHelpers(
  "validationRules"
);

export default {
  name: "refinement-rule-table-row",
  props: {
    validationRuleId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    validationTypes: window.config.enums.submissionRuleValidationTypes
  }),
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapValidationRulesGetters([FIND_BY_ID]),
    validationRule() {
      return this[FIND_BY_ID](this.validationRuleId);
    },
    rejectReason() {
      return this.$store.getters["validationRules/getRejectReason"](
        this.validationRule.rejectReason
      );
    },
    hasRejectReason() {
      return !isNil(this.rejectReason);
    }
  }
};
</script>
