<template>
  <table-overview
    title="Applied Rules"
    icon="far fa-fw fa-globe"
    store="validationRule/submissions"
    :headers="[
      { text: 'Completed at', value: 'completed_at' },
      { text: 'Name', value: 'full_name', sortable: false },
      { text: 'Website', value: 'website', sortable: false },
      { text: 'Subjects', value: 'subjects', sortable: false },
      { text: 'Leads', value: 'leads', sortable: false },
      { text: 'Status', value: 'id', sortable: false },
      {
        text: 'Details',
        align: 'center',
        sortable: false,
        class: 'v-table-th--icon',
        rolesOrPermissions: 'submission_view'
      }
    ]"
    force-initial-sort="completed_at"
    descending
  >
    <validation-rule-applied-table-row
      slot="table-row"
      slot-scope="{ item }"
      :submission-id="submission(item)"
    />
  </table-overview>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";

import TableOverview from "@/components/generic/overview/TableOverview";
import ValidationRuleAppliedTableRow from "@/components/validation-rule/submission/ValidationRuleAppliedTableRow";

const { mapGetters } = createNamespacedHelpers("validationRule");
const { mapGetters: mapSubmissionGetters } = createNamespacedHelpers(
  "validationRule/submissions"
);

export default {
  name: "refinement-Rule-applied-view",
  components: {
    ValidationRuleAppliedTableRow,
    TableOverview
  },
  data: () => ({
    triggerGroupMatchData: undefined
  }),
  computed: {
    ...mapGetters(["id"]),
    ...mapSubmissionGetters([FIND_BY_ID])
  },
  methods: {
    submission(appliedId) {
      return this[FIND_BY_ID](appliedId).submission;
    }
  }
};
</script>
